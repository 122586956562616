import styled from "styled-components";

export const UploadImageStyled = styled("div")`
  display: flex;
  width: 110px;
  height: 110px;
  .upload-image-content {
    transition: all 0.2s linear;
    border: 1px dashed #ccc;
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      border-color: ${({ theme }) => theme?.colors?.border?.blue};
      cursor: pointer;
    }
    .upload-image-icon {
      margin-bottom: 10px;
    }
  }
  .upload-image-input {
    display: none;
  }
`;
