import { Button } from "components";
import PageNotFoundIcon from "pages/notFound/assets/icons/PageNotFoundIcon";
import React from "react";
import { Link } from "react-router-dom";
import { NotFoundPageStyle } from "./NotFoundImage.style";

const NotFoundImage = () => {
  return (
    <NotFoundPageStyle>
      <PageNotFoundIcon width="700px" height="400px" />
      <Link to="/">
        <Button value="Back to home" type="button" />
      </Link>
    </NotFoundPageStyle>
  );
};

export default NotFoundImage;
