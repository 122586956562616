import { FC } from "react";

import { IInputProps } from "./Input.types";
import { StyledInput } from "./Input.style";
import Label from "../Label/Label";
import Error from "../Error/Error";

const Input: FC<IInputProps> = ({
  label = "",
  params,
  error,
  disabled = false,
  placeholder = "",
  className = "",
  type = "text",
}) => {
  return (
    <div className={className}>
      {label && <Label label={label} />}
      <StyledInput
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...params}
        className={`${!!error ? "error" : ""}`}
      />
      {!!error && <Error message={error.message} />}
    </div>
  );
};

export default Input;
