import React from "react";

const QuoteIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1010 4359 c-220 -24 -455 -128 -620 -275 -296 -264 -436 -645 -375
-1021 28 -176 97 -346 192 -483 70 -99 226 -247 327 -309 142 -88 317 -149
475 -167 l64 -7 -6 -106 c-12 -207 -86 -409 -244 -662 -33 -55 -53 -97 -53
-116 0 -26 29 -60 204 -237 112 -113 216 -210 231 -216 44 -17 82 9 215 146
429 443 718 1023 820 1647 49 302 47 689 -5 903 -144 595 -646 965 -1225 903z
m327 -175 c324 -65 598 -320 712 -659 51 -152 64 -253 64 -500 1 -236 -9 -346
-49 -550 -80 -402 -246 -785 -493 -1135 -70 -99 -222 -279 -295 -350 l-46 -45
-140 140 -140 140 36 60 c183 302 280 668 233 884 -15 72 -30 81 -148 91 -117
11 -208 32 -304 70 -418 170 -669 621 -592 1063 75 428 416 753 847 806 88 11
217 5 315 -15z"
        />
        <path
          d="M3850 4359 c-254 -26 -512 -157 -700 -355 -462 -487 -393 -1273 147
-1678 153 -115 315 -183 505 -213 l108 -17 0 -72 c0 -181 -76 -412 -205 -628
-92 -152 -95 -159 -95 -185 0 -19 50 -76 208 -234 114 -115 217 -213 230 -218
40 -16 82 13 207 142 151 156 226 247 340 419 301 452 471 944 516 1490 14
170 6 425 -17 562 -62 371 -278 688 -582 855 -142 79 -262 115 -431 132 -113
12 -110 12 -231 0z m330 -176 c134 -26 286 -101 403 -198 148 -122 280 -337
332 -538 62 -243 56 -659 -14 -999 -110 -529 -354 -993 -751 -1426 l-75 -82
-142 142 -141 143 44 75 c105 176 186 381 219 559 18 97 20 253 4 316 -17 67
-34 77 -147 86 -253 19 -458 115 -635 296 -339 347 -366 900 -62 1280 107 134
229 225 395 295 179 76 364 92 570 51z"
        />
      </g>
    </svg>
  );
};

export default QuoteIcon;
