import { useCallback, useState } from "react";
import { debounce } from "services/debounce/debounce";
import { useTranslation } from "react-i18next";

import "./style.css";

interface InputProps {
  type?: string;
  id?: string;
  className?: string;
  label?: string;
  value?: any;
  name?: string;
  placeholder?: string;
  setFilter?: any;
}

const CustomInput = ({
  type = "text",
  id,
  className,
  label,
  value,
  name,
  placeholder = "COMMON.SEARCH",
  setFilter,
}: InputProps) => {
  const [query] = useState(value);
  const { t } = useTranslation();

  const changeHandler = (event: any) => {
    const { value, name } = event.target;
    setFilter((prev: any) => ({ ...prev, [name]: value }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 0), []);

  return (
    <div className={`custom-input-group ${className}`}>
      {label && <div className={"input-label"}>{label}</div>}
      <input
        id={id}
        name={name}
        type={type}
        autoComplete="off"
        defaultValue={query}
        className={`custom-input-all`}
        placeholder={t([placeholder])}
        onChange={debouncedChangeHandler}
      />
    </div>
  );
};

export default CustomInput;
