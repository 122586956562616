import OrderIcon from "components/shared/Sidebar/assets/icon/OrderIcon";
import PublishingIcon from "components/shared/Sidebar/assets/icon/PublishingIcon";
import PackagesIcon from "components/shared/Sidebar/assets/icon/PackagesIcon";
import BookIcon from "components/shared/Sidebar/assets/icon/BookIcon";
import UserIcon from "components/shared/Navbar/assets/icon/UserIcon";
import AuthorIcon from "components/shared/Sidebar/assets/icon/AuthorIcon";
import HrIcon from "components/shared/Sidebar/assets/icon/HrIcon";
import NewsIcon from "components/shared/Sidebar/assets/icon/NewsIcon";
import SettingIcon from "components/shared/Sidebar/assets/icon/SettingIcon";
import GenreIcon from "components/shared/Sidebar/assets/icon/GenreIcon";
import { ISidebarItem } from "./Sidebar.types";
import DiscountIcon from "./assets/icon/DiscountIcon";
import CategoryIcon from "./assets/icon/CategoryIcon";
import CallRequestIcon from "./assets/icon/CallRequestIcon";
import QuoteIcon from "./assets/icon/QuoteIcon";

import { ReactComponent as Partners } from "assets/images/svg/partner.svg";
import { ReactComponent as Banners } from "assets/images/svg/banner.svg";
import { ReactComponent as Promocdes } from "assets/images/svg/promocode.svg";
import { ReactComponent as Comments } from "assets/images/svg/messages.svg";
import { ReactComponent as Dashboard } from "assets/images/svg/dashboard.svg";

export const SIDEBAR_LIST: ISidebarItem[] = [
  {
    id: "dashboard",
    path: "/",
    icon: <Dashboard />,
    title: "DASHBOARD",
    insidePaths: [],
  },
  {
    id: "genre",
    path: "/genres",
    icon: <GenreIcon />,
    title: "GENRES",
    insidePaths: ["/genres", "/genres/add", "/genres/edit"],
  },
  {
    id: "book",
    path: "/books",
    icon: <BookIcon />,
    title: "BOOKS",
    insidePaths: ["/books", "/books/add", "/books/edit", "/books/details"],
  },
  {
    id: "category",
    path: "/category",
    icon: <CategoryIcon />,
    title: "CATEGORIES",
    insidePaths: ["/category", "/category/info", "/category/info/add-book"],
  },
  {
    id: "package",
    path: "/packages",
    icon: <PackagesIcon />,
    title: "PACKAGES",
    insidePaths: [
      "/packages",
      "/packages/add",
      "/packages/edit",
      "/packages/details",
    ],
  },
  {
    id: "publisher",
    path: "/publishing",
    icon: <PublishingIcon />,
    title: "PUBLISHING",
    insidePaths: ["/publishing"],
  },
  {
    id: "order",
    path: "/orders",
    icon: <OrderIcon />,
    title: "ORDERS",
    insidePaths: ["/orders", "/orders/info", "/orders/edit", "/orders/add"],
  },
  // {
  //   id: "call",
  //   path: "/call-request",
  //   icon: <CallRequestIcon />,
  //   title: "CALL_REQUEST",
  //   insidePaths: ["/call-request"],
  // },
  {
    id: "user",
    path: "/users",
    icon: <UserIcon />,
    title: "USERS",
    insidePaths: ["/users"],
  },
  {
    id: "author",
    path: "/authors",
    icon: <AuthorIcon />,
    title: "AUTHORS",
    insidePaths: ["/authors", "/authors/add", "/authors/edit"],
  },
  {
    id: "hr",
    path: "/hr",
    icon: <HrIcon />,
    title: "HR",
    open: false,
    children: [
      {
        id: "role",
        title: "ROLES",
        path: "/hr/roles",
        insidePaths: ["/hr/roles", "/hr/roles/add", "/hr/roles/edit"],
      },
      {
        id: "employee",
        title: "EMPLOYEES",
        path: "/hr/employees",
        insidePaths: [
          "/hr/employees",
          "/hr/employees/add",
          "/hr/employees/edit",
        ],
      },
    ],
  },
  {
    id: "news",
    path: "/news",
    icon: <NewsIcon />,
    title: "NEWS",
    insidePaths: ["/news", "/news/add", "/news/edit"],
  },
  {
    id: "discount",
    path: "/discount",
    icon: <DiscountIcon />,
    title: "DISCOUNT",
    insidePaths: ["/discount", "/discount/add", "/discount/edit"],
  },
  {
    id: "quote",
    path: "/quote",
    icon: <QuoteIcon />,
    title: "QUOTE",
    insidePaths: ["/quote"],
  },
  {
    id: "Partners",
    path: "/partner",
    icon: <Partners />,
    title: "Partners",
    insidePaths: ["/partner"],
  },
  {
    id: "banner",
    path: "/banner",
    icon: <Banners />,
    title: "Banner",
    insidePaths: ["/banner"],
  },
  {
    id: "promoCode",
    path: "/promoCode",
    icon: <Promocdes />,
    title: "promoCode",
    insidePaths: ["/promoCode"],
  },
  {
    id: "comment",
    path: "/comment",
    icon: <Comments />,
    title: "Comment",
    insidePaths: ["/comment"],
  },
  {
    id: "settings",
    path: "/setting/supplier-price/add",
    icon: <SettingIcon />,
    title: "SETTINGS",
    children: [
      {
        id: "delivery",
        path: "/setting/supplier-price/add",
        title: "DELIVERY",
        insidePaths: ["/setting/supplier-price/add"],
      },
      {
        id: "setting",
        path: "/setting/expire-month",
        title: "EXPIRE_MONTH",
        insidePaths: ["/setting/expire-month"],
      },
      {
        id: "setting",
        path: "/setting/bestseller",
        title: "BESTSELLER",
        insidePaths: ["/setting/bestseller"],
      },
    ],
  },
];

export const sidebarOpenWith = "270px";

export const sidebarCloseWith = "80px";
