import { ERROR_MESSAGES } from "constants/error";
import { REQUEST_STATUS } from "hooks";
import get from "lodash.get";
import React, { FC, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import UploadPlusIcon from "../../assets/UploadPlusIcon";
import { MultipleUploadImageContext } from "../../context";
import { IUseMultipleUploadImage } from "../../context/MultipleUploadImageContext.types";
import { UploadImageStyled } from "./UploadImage.style";
import { IUploadImage } from "./UploadImage.types";

const UploadImage: FC<IUploadImage> = ({
  setUploadImageUrlList,
  name,
  setValue,
}) => {
  const { t } = useTranslation();
  const {
    state: {
      uploadImageStates: {
        uploadImageData,
        uploadImageStatus,
        uploadImageError,
      },
    },
    actions: { uploadImage },
  } = useContext<IUseMultipleUploadImage>(MultipleUploadImageContext);

  useEffect(() => {
    if (uploadImageStatus === REQUEST_STATUS.success) {
      if (get(uploadImageData, "data", undefined)) {
        setUploadImageUrlList((prev) => {
          let newImageUrlList = prev.concat(
            get(uploadImageData, "data")
          ) as string[];
          setValue(name, newImageUrlList);
          return newImageUrlList;
        });
        toast.success(t(ERROR_MESSAGES[get(uploadImageData, "statusCode", 0)]));
      }
    }
  }, [uploadImageStatus]);

  const fileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const files = target.files || [];
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", "img");
    uploadImage(formData);
  };

  return (
    <UploadImageStyled>
      <label htmlFor="upload-image-input" className="upload-image-content">
        <span className="upload-image-icon">
          <UploadPlusIcon />
        </span>
        <span className="upload-image-text">Yuklash</span>
      </label>
      <input
        type="file"
        className="upload-image-input"
        id="upload-image-input"
        accept={".svg, .png, .jpg, .jpeg, .ico, .webp"}
        onChange={fileInputChange}
      />
    </UploadImageStyled>
  );
};

export default UploadImage;
