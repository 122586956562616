import React, { FC, useEffect, useMemo, useState } from "react";
import { useRequest } from "../../../hooks/useRequest/useRequest";
import { uploadFileType } from "../../../constants/upload";
import UploadFileIcon from "./assets/UploadFileIcon";
import { UploadFileIconLoader, UploadFileStyled } from "./UploadFile.style";
import { UploadFilePropsType } from "./UploadFile.types";
import { sliceUrl } from "./utils/sliceUrl";
import toast from "react-hot-toast";
import UploadAudioIcon from "pages/books/details/components/assets/UploadAudioIcon";
import UploadEbookIcon from "pages/books/details/components/assets/UploadEbookIcon";
import { REQUEST_STATUS } from "hooks";
import { ERROR_MESSAGES } from "constants/error";
import { useTranslation } from "react-i18next";

const UploadFile: FC<UploadFilePropsType> = (props) => {
  const {
    params,
    upload = () => {},
    name = "file",
    dataUrl,
    type = "img",
    setValue,
    className = "",
    defaultImageUrl = undefined,
    setDetails,
    setAudioDuration,
    audioDuration,
  } = props;
  const { t } = useTranslation();
  const [client, data, status, error] = useRequest();
  const [img, setImg] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (!!defaultImageUrl) {
      setImg(defaultImageUrl);
      setValue(name, defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const fileUpload = async (e: any) => {
    try {
      const formState = new FormData();
      if (setDetails) {
        setDetails(e.target.files[0]);
      }
      formState.append("file", e.target.files[0]);
      formState.append("type", uploadFileType[type]);
      const res = await client.post(dataUrl, formState);
      if (res.data) {
        if (setAudioDuration) {
          let file = e.target.files[0];
          const duration = await getDuration(file);
          setAudioDuration(duration);
        }
        if (setValue) setValue(name, res.data);
        upload(res.data);
        setImg(res.data);
        toast.success("Muvvaffaqiyatli yuklandi");
      }
    } catch (e: any) {
      setImg(undefined);
    }
  };

  function getDuration(file: any) {
    const url = URL.createObjectURL(file);

    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload = "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function () {
        resolve(audio.duration);
      };
    });
  }

  const renderIcon = () => {
    if (REQUEST_STATUS.loading === status) {
      return <UploadFileIconLoader />;
    } else if (setAudioDuration) {
      if (!audioDuration && img) {
        return <UploadFileIconLoader />;
      } else {
        return <UploadAudioIcon width="25px" height="25px" />;
      }
    } else if (type === "img") {
      return <UploadFileIcon width="17px" height="17px" />;
    } else if (type === "audio") {
      return <UploadAudioIcon width="25px" height="25px" />;
    } else if (type === "ebook") {
      return <UploadEbookIcon width="25px" height="25px" />;
    } else return null;
  };

  return (
    <div>
      <UploadFileStyled className={className}>
        <label htmlFor="uploadFile">
          <input
            {...params}
            className="uploadFile"
            type="file"
            id="uploadFile"
            onChange={fileUpload}
            accept={
              type === "img"
                ? ".svg, .png, .jpg, .jpeg, .ico, .webp"
                : type === "audio"
                ? "audio/*"
                : type === "ebook"
                ? ".pdf, .epub"
                : ""
            }
            disabled={status === REQUEST_STATUS.loading}
          />
          <div className="url">{img ? sliceUrl(img) : null}</div>
        </label>
        <div className="uploadFileIcon">{renderIcon()}</div>
      </UploadFileStyled>
    </div>
  );
};

export default UploadFile;
