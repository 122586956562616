import React from "react";


const GenreIcon = () => {
    return <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.21436 15.7857V2.21429C1.21436 1.83541 1.36487 1.47204 1.63277 1.20414C1.90068 0.936226 2.26405 0.785717 2.64293 0.785717H7.91436C8.24069 0.776209 8.56044 0.878764 8.82036 1.07631C9.08029 1.27385 9.26471 1.55446 9.34293 1.87143L9.78578 3.64286H18.3572C18.7361 3.64286 19.0995 3.79337 19.3674 4.06128C19.6353 4.32919 19.7858 4.69255 19.7858 5.07143V15.7857C19.7858 16.1646 19.6353 16.528 19.3674 16.7959C19.0995 17.0638 18.7361 17.2143 18.3572 17.2143H2.64293C2.26405 17.2143 1.90068 17.0638 1.63277 16.7959C1.36487 16.528 1.21436 16.1646 1.21436 15.7857Z" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6855 7.92857V12.9286" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.18555 10.4286H13.1855" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}
export default GenreIcon;