import { useState } from "react";
import { useRequest } from "hooks";

export const useCommon = () => {
  const [render, setRender] = useState<boolean>(false);
  const [updatedOrder, setUpdatedOrder] = useState<any>();
  const [newComment, setNewComment] = useState<any>();
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();

  const getRole = async (data: any) => {
    await getRoleClient.get("employee/profile");
  };

  return {
    state: {
      createEbookState: {
        getRoleData,
        getRoleStatus,
        getRoleError,
      },
      render,
      updatedOrder,
      newComment,
    },
    actions: {
      getRole,
      setRender,
      setUpdatedOrder,
      setNewComment,
    },
  };
};
