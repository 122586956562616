import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { FC } from "react";
import FirstArrowIcon from "../../assets/FirstArrowIcon";
import LastArrowIcon from "../../assets/LastArrowIcon";
import LeftArrowIcon from "../../assets/LeftArrowIcon";
import RightArrowIcon from "../../assets/RightArrowIcon";
import { TablePaginationActionsPropsType } from "./TablePaginationActions.types";

const TablePaginationActions: FC<TablePaginationActionsPropsType> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <Tooltip title="first page">
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 1}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastArrowIcon /> : <FirstArrowIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="previous page">
        <span>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 1}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? <RightArrowIcon /> : <LeftArrowIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="next page">
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page > Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? <LeftArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="last page">
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page > Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstArrowIcon /> : <LastArrowIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default TablePaginationActions;
