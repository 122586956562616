import React from "react";
import LoginProvider from "../context/LoginProvider";
import Login from "./Login";

const Index = () => {
  return (
    <LoginProvider>
      <Login />
    </LoginProvider>
  );
};

export default Index;
