import { TableFooter } from "@mui/material";
import styled from "styled-components";

export const TableFooterStyled = styled(TableFooter)`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors?.bg?.whiteBg};
  border-top: 1px solid #000 !important;
  .MuiTableRow-root.MuiTableRow-footer {
    .MuiTableCell-root.MuiTableCell-footer {
      min-width: 100% !important;
    }
    .MuiBox-root {
      .MuiButtonBase-root.MuiIconButton-root {
        &:hover {
          cursor: pointer;
        }
        width: 36px;
        height: 36px;
        svg {
          width: 100% !important;
          height: 100% !important ;
          path {
            fill: "#000" !important ;
          }
        }
      }
    }
  }
`;
