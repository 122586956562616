import React, { FC, Suspense, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Label, Error } from "components/index";
import UploadIcon from "components/common/Upload/assets/UploadIcon";
import { useRequest } from "hooks";
import { uploadFileType } from "constants/upload";
import { UploadContent } from "./Upload.style";
import { UploadContentPropsType } from "./Upload.types";
import toast from "react-hot-toast";
import { REQUEST_STATUS } from "hooks";
import { ERROR_MESSAGES } from "constants/error";

const Upload: FC<UploadContentPropsType> = ({
  upload = () => {},
  name = "file",
  dataUrl = "",
  type = "img",
  setValue,
  error,
  control,
  rules = { required: false },
  defaultImageUrl = "",
}) => {
  const [uploadClient, uploadData, uploadStatus, uploadError] = useRequest();
  const { t } = useTranslation();
  const [img, setImg] = useState<string>("");

  useMemo(() => {
    if (!!defaultImageUrl) setImg(defaultImageUrl);
  }, [defaultImageUrl]);

  const fileUpload = async (e: any) => {
    if (e.target.files[0]) {
      try {
        const formState = new FormData();
        formState.append("file", e.target.files[0]);
        formState.append("type", uploadFileType[type]);
        const data = await uploadClient.post(dataUrl, formState);
        if (data) {
          toast.success(t(ERROR_MESSAGES[data?.statusCode]));
          upload(data.data);
          setImg(data.data);
          if (setValue) await setValue(name, data.data);
        }
      } catch (e: any) {
        toast.error(t(ERROR_MESSAGES[e?.response?.data?.statusCode]));

        setImg("");
      }
    } else {
      if (setValue) await setValue(name, null);
    }
  };

  const renderUploadContext = () => {
    if (uploadStatus === REQUEST_STATUS.initial) {
      if (img) {
        return (
          <Suspense
            fallback={
              <div className=" loaderContent  justify-content-center align-items-center">
                <CircularProgress />
              </div>
            }
          >
            <img
              className="uploadImage"
              src={process.env.REACT_APP_BASE_URL + img}
              alt={"Upload image"}
              loading={"lazy"}
            />
          </Suspense>
        );
      } else
        return (
          <div className="uploadIcon">
            <UploadIcon />
          </div>
        );
    } else if (uploadStatus === REQUEST_STATUS.loading) {
      return (
        <div className=" loaderContent  justify-content-center align-items-center">
          <CircularProgress />
        </div>
      );
    } else if (uploadStatus === REQUEST_STATUS.success) {
      return (
        <img
          className="uploadImage"
          src={process.env.REACT_APP_BASE_URL + img}
          alt={"Upload image"}
          loading="lazy"
        />
      );
    } else return null;
  };

  return (
    <>
      <Label label={t("COMMON.UPLOAD_LABEL")} />
      <UploadContent error={!!error}>
        <label
          htmlFor="upload"
          className={`${uploadStatus === "LOADING" ? "loading" : ""}`}
        >
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, ...field } }) => (
              <input
                type="file"
                id="upload"
                onChange={(e) => {
                  fileUpload(e);
                  onChange(e);
                }}
                accept=".svg, .png, .jpg, .jpeg, .ico, .webp "
                disabled={uploadStatus === REQUEST_STATUS.loading}
              />
            )}
          />
          {renderUploadContext()}
        </label>
      </UploadContent>
      {!!error && <Error message={t("VALIDATION.PICTURE_INPUT")} />}
    </>
  );
};

export default Upload;
