import { FC, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { get } from "lodash";
import { schema } from "./Schema";

type TLIne = {
  data?: {};
  options?: object;
};

const Line: FC<TLIne> = ({ data = {}, options = schema }) => {
  const [state] = useState(options);

  return (
    <div className="mt-24">
      <ReactApexChart
        type="area"
        height="350"
        options={get(state, "options")}
        labels={get(data, "label", [])}
        series={[
          {
            name: get(data, "label", ""),
            data: get(data, "value", []),
          },
        ]}
      />
    </div>
  );
};

export default Line;
