import styled from "styled-components";
import { TextAreaStyledType } from "./TextArea.types";

export const TextAreaStyled = styled.textarea<TextAreaStyledType>`
  height: 200px;
  border: 1px solid
    ${(props) => props.theme.colors.border[props.error ? "red" : "grey"]};
  border-radius: 8px;
  outline: none;
  padding: 20px;
  width: 100%;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.lightBlack};
`;
