import styled from "styled-components";

export const LoginPageStyle = styled.div`
  height: 100vh;
  .MuiGrid-root.MuiGrid-container {
    height: 100%;
    .loginPageRight {
      background: ${({ theme }) => theme.colors.bg.mainLightBg};
    }
    .loginPageLeft {
      padding: 0 100px;
      padding-top: 30px;
      padding-bottom: 100px;
      svg {
        margin-bottom: 170px;
      }
    }
  }
`;
