import { ERROR_MESSAGES } from "constants/error";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import axios from "services/api/client";

import { REQUEST_STATUS } from "./useRequest.constants";
import { DataType, TApiRequestMetod } from "./useRequest.types";

export const useRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<DataType | undefined>();
  const [status, setStatus] = useState<string>(REQUEST_STATUS.initial);
  const [error, setError] = useState<any>();

  const get = async (url: string) => await sendRequest("get", url);

  const post = async (url: string, data: any) =>
    await sendRequest("post", url, data);

  const put = async (url: string, data: any) =>
    await sendRequest("put", url, data);

  const deleteRequest = async (url: string, data: any) =>
    await sendRequest("delete", url);

  const sendRequest = async (
    method: TApiRequestMetod,
    url: string,
    data?: any
  ) => {
    setStatus(REQUEST_STATUS.loading);
    try {
      const res = await axios[method](url, data);
      setData(res?.data);
      setStatus(REQUEST_STATUS.success);
      return res?.data;
    } catch (err: any) {
      if (err?.response?.status === 401) {
        navigate("/login");
        localStorage.clear();
      } else if (err?.response?.status === 404) {
        navigate("/page-not-found");
      } else {
        setError(err);
        setStatus(REQUEST_STATUS.failed);
        if (!!err?.response) {
          console.log(err?.response.data.message, 1);
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(t(ERROR_MESSAGES[703]));
        }
      }
    }
  };

  return [
    {
      get,
      post,
      put,
      deleteRequest,
    },
    data,
    status,
    error,
  ];
};
