import { CommonContext } from "context";
import get from "lodash.get";
import io from "socket.io-client";
import toast from "react-hot-toast";

import Loader from "pages/loader/container/Loader";
import { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Routes from "routes/container/Routes";
import { hasToken } from "routes/utils/hasToken/hasToken";
import browserStorage from "services/storage/browserStorage";
import { Navbar, Sidebar } from "../../components";
import { LayoutStyled } from "../style/Layout";

const Layout = () => {
  const [collapse, setCollapse] = useState(false);
  const navigate = useNavigate();
  const [socket, setSocket] = useState<any>();

  const {
    state: {
      createEbookState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: { getRole, setRender, setUpdatedOrder, setNewComment },
  } = useContext(CommonContext);

  useEffect(() => {
    getRole();
    if (!hasToken()) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    browserStorage.set("role", get(getRoleData, "data.role"));
  }, [getRoleStatus]);

  useEffect(() => {
    const newSocket = io("https://backend.book.uz", {
      path: "/admin-api/socket.io",
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      transports: ["polling"],
    });
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("newOrder", (data: any) => {
        if (data) {
          setRender((prev: boolean) => !prev);
          toast.success("Yangi buyurtma qo'shildi", { duration: 7000 });
        }
      });
      socket.on("updatedOrder", (data: any) => {
        setUpdatedOrder(data?.data);
      });
      socket.on("newComment", (data: any) => {
        // console.log("layout data", data);
        if (data) {
          setNewComment(data);
          toast.success(
            data.data.book.name
              ? `${data.data?.book?.name.uz} kitobiga yangi fikr qo'shildi`
              : "Yangi fikr qo'shildi",
            { duration: 7000 }
          );
        }
      });

      return () => {
        socket.off("connect");
        socket.off("newOrder");
        socket.off("updatedOrder");
      };
    }
  }, [socket]);

  return (
    <LayoutStyled collapse={collapse}>
      <div className="sidebar-content">
        <Sidebar collapse={collapse} setCollapse={setCollapse} />
      </div>

      <div className={`wrapper`} id="wrapper">
        <Navbar />
        <div className="wrapper-content">
          <Suspense fallback={<Loader />}>
            <Routes />
          </Suspense>
        </div>
      </div>
    </LayoutStyled>
  );
};

export default Layout;
