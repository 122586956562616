import { FC } from "react";
import { MenuItem, TextField } from "@mui/material";
import { AutoComplateStyled } from "./Select.style";
import { ISelectProps } from "./Select.types";
import Label from "../Label/Label";
import { Error } from "../../index";
import { Controller } from "react-hook-form";
import { useLanguage } from "services/useLanguage/useLanguage";

const Select: FC<ISelectProps> = ({
  label = "",
  options = [],
  error,
  name = "",
  className = "",
  handleInputChange = () => {},
  control,
  rules = { required: false },
  defaultValue,
  dataKey = "name",
  defaultInputValue = "",
  multiple = false,
  onChangeSelect,
  placeholder = "",
  keyName = "_id",
  isTranslation = false,
  disabled,
  isLoading = false,
  ...props
}) => {
  const [t] = useLanguage();

  const getOptionLabel = (option: any) => {
    if (option[dataKey]) {
      if (isTranslation) {
        return t(option[dataKey]);
      } else return option[dataKey];
    }
    return "";
  };

  const renderOption = (renderOptionProps: any, option: any) => {
    return (
      <MenuItem {...renderOptionProps} key={option?._id}>
        {getOptionLabel(option)}
      </MenuItem>
    );
  };

  return (
    <div className={className}>
      {label && <Label label={label} />}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...field } }) => {
          return (
            <AutoComplateStyled
              rules={rules}
              id="select"
              disabled={disabled}
              multiple={multiple}
              value={field.value}
              isSearchable={true}
              options={options}
              defaultValue={defaultValue}
              className={!!error ? "error" : ""}
              getOptionLabel={getOptionLabel}
              loading={isLoading}
              loadingText="Loading..."
              noOptionsText="Data not found"
              filterOptions={() => {
                if (!options.length) return [];
                return options;
              }}
              isOptionEqualToValue={(option: any, selected: any) =>
                Array.isArray(selected)
                  ? selected.some((s) => s[keyName] === option[keyName])
                  : option[keyName] === selected?.[keyName]
              }
              onChange={(event: any, dataItem: any) => {
                onChange(dataItem);
                if (onChangeSelect) {
                  onChangeSelect(dataItem);
                }
              }}
              renderOption={renderOption}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label=""
                  placeholder={placeholder}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              )}
              {...props}
            />
          );
        }}
      />
      {!!error && <Error message={error?.message} />}
    </div>
  );
};

export default Select;
