import { CommonContext } from "context";
import get from "lodash.get";
import { useContext } from "react";

const RoleManager = () => {
  const {
    state: {
      createEbookState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: {},
  } = useContext(CommonContext);
  const hasAccess = (name: string) => {
    
    try {
      return get(getRoleData, "data.role")[name];
      // return true;
    } catch (err) {
      return false;
    }
  };

  return [hasAccess];
};

export default RoleManager;
