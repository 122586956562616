import React, { FC } from "react";
import { Link } from "react-router-dom";

import { IEditButtonProps } from "./EditButton.types";
import { Icons } from "components";

const EditButton: FC<IEditButtonProps> = ({ to }) => {
  return (
    <Link
      onClick={(e: any) => e.stopPropagation()}
      to={to}
      className="text-decoration-none mx-3"
    >
      <Icons.Edit />
    </Link>
  );
};

export default EditButton;
