import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOutsideHandler from "../../../hooks/outsideHandler/outsideHandler";
import { debounce } from "../../../services/debounce/debounce";
import { SearchParams } from "../../../services/urlParams/urlParams";
import { REQUEST_STATUS, useRequest } from "hooks";
import chevron from "./chevron.svg";
import "./style.css";

interface CustomSelect {
  optionAllView?: boolean;
  onSearch?: any;
  searchable?: boolean;
  label?: string;
  children?: any;
  className?: string;
  name?: string;
  style?: any;
  value?: string;
  firstSelected?: boolean;
  disabled?: boolean;
  options?: any;
  dataUrl?: string;
  optional?: any;
  field?: string;
  setFilter?: any;
  id?: string;
  staticOptions?: any;
  onChange?: any;
  defaultOptions?: any;
  isClearable?: boolean;
}

const CustomSelect = ({
  optionAllView = true,
  searchable = false,
  label,
  children,
  className,
  name = "name",
  style = null,
  value,
  disabled = false,
  dataUrl = "",
  optional,
  field = "name",
  setFilter,
  id = "id",
  staticOptions = [],
  onChange = undefined,
  defaultOptions = undefined,
  isClearable = true,
}: CustomSelect) => {
  const [, getParams] = SearchParams();
  const [isOpen, setIsOpen] = useState<any>(false);
  const [selectedOption, setSelectedOption] = useState<any>(undefined);
  const [optionsSelect, setOptionsSelect] = useState<any>([]);
  const outsideOfSelectRef = useRef<any>();
  const [client, response, status, error] = useRequest();
  const { t } = useTranslation();

  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  useOutsideHandler(outsideOfSelectRef, setIsOpen);

  const handleChange = (item: any) => {
    setSelectedOption(item);
    setIsOpen(false);
    setFilter((prev: any) => {
      return {
        ...prev,
        [name]: item[field] + " " + (item.lastName ? item.lastName : ""),
        [id]: item._id,
      };
    });
    if (onChange) {
      onChange(item);
    }
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        onClick: () => handleChange(child.props.value),
        className: `list-item ${
          child.props.value === selectedOption?.value ? "active" : ""
        }`,
      });
    });
  };

  useEffect(() => {
    const childArray = React.Children.toArray(children);
    for (const child of childArray) {
      // @ts-ignore
      if ("props" in child && child.props.value === value) {
        return setSelectedOption({
          value: child.props.value,
          name: child.props.children,
          // params: child.props.params
        });
      }
    }
  }, [value]);

  const handleSearch = async (event: any) => {
    let data: any;
    if (event) {
      data = {
        limit: 10,
        page: 1,
        search: event.target.value,
        ...optional,
      };
    } else {
      data = {
        limit: 10,
        page: 1,
        search: "",
        ...optional,
      };
    }
    if (dataUrl && searchable) {
      await client.get(
        `${dataUrl}?limit=10&page=1${
          data.search ? `&search=${data.search}` : ""
        }${optional ? optional : ""}`
      );
      try {
        // const res = await api.apiCall<BaseResponse<any>>(dataUrl, data);
        // if (res.data?.data) {
        //   setOptionsSelect(res.data.data.data);
        // }
      } catch (e: any) {}
    }
  };
  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      setOptionsSelect(response.data.data);
    }
  }, [status]);

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    handleSearch(undefined);
    defaultOptionsFunction();
    getSelectOptions();
  }, []);

  const defaultOptionsFunction = () => {
    if (getParams()[name] && getParams()[id]) {
      setSelectedOption({
        _id: getParams()[id],
        [field]: getParams()[name],
      });
    } else if (defaultOptions) {
      setSelectedOption(defaultOptions);
      setFilter((prev: any) => {
        return {
          ...prev,
          [id]: defaultOptions._id,
          [name]:
            defaultOptions[field] +
            " " +
            (defaultOptions.lastName ? defaultOptions.lastName : ""),
        };
      });
    }
  };

  const getSelectOptions = async () => {
    if (!searchable) {
      if (!staticOptions.length) {
        if (dataUrl) {
          let optionals = {};
          try {
            // const response = await api.apiCall<BaseResponse<any>>(
            //   dataUrl,
            //   optionals,
            //   "get"
            // );
            // if (response.data) setOptionsSelect(response.data.data);
          } catch (e: any) {}
        }
      } else {
        setOptionsSelect(staticOptions);
      }
    }
  };

  return (
    <div style={style} className={`custom-input ${className}`}>
      {label && <div className={"select-label"}>{label}</div>}
      <div className={`main`}>
        <div ref={outsideOfSelectRef} className={"drop-down-container"}>
          <div className={"drop-down-header"} onClick={toggling}>
            {
              <>
                <div className="drop-down-header-content">
                  {selectedOption ? (
                    <>
                      <p className="mb-0 ">
                        {selectedOption[field] +
                          " " +
                          (selectedOption.lastName
                            ? selectedOption.lastName
                            : "")}
                      </p>
                      {isClearable && (
                        <div
                          className="ms-2"
                          onClick={async (e: any) => {
                            e.stopPropagation();
                            await setFilter((prev: any) => {
                              return {};
                              // ...prev,
                              //   [name]: "",
                              //   [id]: "",
                            });
                            await setSelectedOption(undefined);
                          }}
                        >
                          &#10005;
                        </div>
                      )}
                    </>
                  ) : (
                    <>{t("COMMON.ALL")}</>
                  )}
                </div>
                {/* {optionAllView ? "Hammasi" : "Tanlanmagan"} */}
              </>
            }
            <img
              className={`chevron ${isOpen ? "chevron-rotate" : ""}`}
              src={chevron}
              alt=""
              loading="lazy"
            />
          </div>
          {isOpen && (
            <div className={"drop-down-list-container"}>
              {" "}
              {searchable && (
                <div className="search-input-content">
                  <input
                    onChange={debounceSearch}
                    className={"search-option"}
                    type="text"
                    placeholder={t("COMMON.SEARCH")}
                  />
                </div>
              )}
              <div className={"drop-down-list"}>
                {optionsSelect?.map((item: any) => {
                  return (
                    <option
                      key={item._id}
                      onClick={() => handleChange(item)}
                      className={`list-item ${
                        selectedOption?._id === item._id ? "active" : ""
                      }`}
                      value={"true"}
                    >
                      {item[field] + " " + (item.lastName ? item.lastName : "")}
                    </option>
                  );
                })}
                {renderChildren()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
