import { AxiosResponse } from "axios";
import {
  QueryKey,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import query from "services/query";
import { TVariables } from "services/query/mutationFn";

interface IUseMutate {
  url: string;
  method?: string;
  urlSearchParams?: Record<string, unknown>;
  onSuccess?: (data: unknown) => void;
  onError?: (err: unknown) => void;
  queryKey?: QueryKey;
}

function useMutate({
  url,
  method = "post",
  urlSearchParams,
  onSuccess = () => {
    // Empty
  },
  onError = () => {
    // Empty
  },
  queryKey,
}: IUseMutate): UseMutationResult<any, unknown, TVariables, unknown> {
  const mutation = useMutation({
    mutationKey: queryKey ?? [url, urlSearchParams],
    mutationFn: async (variables: TVariables) => {
      try {
        const res: AxiosResponse | undefined = await query.mutationFn({
          url,
          method,
          queryKey,
          variables,
          urlSearchParams,
          onSuccess,
          onError,
        });
        onSuccess(res?.data);
        return res?.data;
      } catch (error) {
        onError(error);
      }
    },
  });
  return mutation;
}

export default useMutate;
