import { ReactComponent as Dashboard } from "assets/images/svg/dashboard.svg";
import { ReactComponent as Books } from "assets/images/svg/book.svg";
import { ReactComponent as BoldBooks } from "assets/images/svg/boldBook.svg";
import { ReactComponent as Settings } from "assets/images/svg/setting.svg";
import { ReactComponent as Histories } from "assets/images/svg/histories.svg";
import { ReactComponent as Search } from "assets/images/svg/search.svg";
import { ReactComponent as Audio } from "assets/images/svg/audio-square.svg";
import { ReactComponent as AudioList } from "assets/images/svg/music-play.svg";
import { ReactComponent as CloseFormat } from "assets/images/svg/close-circle.svg";
import { ReactComponent as Exel } from "assets/images/svg/exel.svg";
import { ReactComponent as Logo } from "assets/images/svg/logo.svg";
import { ReactComponent as LogoOut } from "assets/images/svg/logout.svg";
import { ReactComponent as Repeat } from "assets/images/svg/repeat-circle.svg";
import { ReactComponent as Income } from "assets/images/svg/trend-up.svg";
import { ReactComponent as Delete } from "assets/images/svg/delete.svg";
import { ReactComponent as Edit } from "assets/images/svg/edit.svg";
import { ReactComponent as FilterColumn } from "assets/images/svg/thFilter.svg";
import { ReactComponent as PartnerDashboard } from "assets/images/svg/partnerDashboard.svg";
import { ReactComponent as PartnerHistories } from "assets/images/svg/PartnerHistories.svg";
import { ReactComponent as ReplyComment } from "assets/images/svg/replyComment.svg";
// dashboard
import { ReactComponent as Sesions } from "assets/images/svg/Sesions.svg";
import { ReactComponent as Pageview } from "assets/images/svg/Pageview.svg";
import { ReactComponent as Duration } from "assets/images/svg/duration.svg";
import { ReactComponent as TotalUsers } from "assets/images/svg/TotalUsers.svg";

export default {
  Sesions,
  Pageview,
  Duration,
  TotalUsers,

  PartnerDashboard,
  ReplyComment,
  PartnerHistories,
  FilterColumn,
  Dashboard,
  Edit,
  Delete,
  Books,
  BoldBooks,
  Settings,
  Histories,
  Search,
  Audio,
  Repeat,
  Income,
  Exel,
  Logo,
  LogoOut,
  AudioList,
  CloseFormat,
};
