import React, { FC } from "react";
import { LoginButtonStyled } from "./LoginButton.styled";
import { ILoginButtonProps } from "./LoginButton.types";

const LoginButton: FC<ILoginButtonProps> = ({
  value,
  type,
  onClick,
  className,
  disabled,
}) => {
  return (
    <LoginButtonStyled
      className={className}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {value}
    </LoginButtonStyled>
  );
};

export default LoginButton;
