import React, { FC, useState } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRangePicker } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePickerRangePropsType } from "./DatePickerRange.types";
import { get } from "lodash";

const DatePicerRange: FC<DatePickerRangePropsType> = (props) => {
  const { className = "" } = props;
  const [value, setValue] = useState<any>([null, null]);
  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <DateRangePicker
            calendars={2}
            value={value}
            onChange={(newValue: object) => {
              setValue(newValue);
            }}
            renderInput={(startProps: object, endProps: object) => (
              <React.Fragment>
                <TextField
                  {...startProps}
                  value={get(startProps, "inputProps.value")}
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField
                  {...endProps}
                  value={get(endProps, "inputProps.value")}
                />
              </React.Fragment>
            )}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePicerRange;
