import React, { FC, Fragment, useContext, useState } from "react";
import { UploadImage, UploadImageCard } from "../components";
import { MultipleUploadImageContext } from "../context";
import { REQUEST_STATUS } from "hooks";
import { IUseMultipleUploadImage } from "../context/MultipleUploadImageContext.types";
import { MultipleUploadImageStyled } from "./MultipleUploadImage.style";
import { IMultipleUploadImage } from "./MultipleUploadImage.types";
import Label from "components/form/Label/Label";
import { useTranslation } from "react-i18next";

const MultipleUploadImage: FC<IMultipleUploadImage> = ({
  defaultUploadImgUrlList = [],
  name,
  setError,
  setValue,
}) => {
  const { t } = useTranslation();
  const [uploadImageUrlList, setUploadImageUrlList] = useState<string[]>(
    defaultUploadImgUrlList
  );

  const {
    state: {
      uploadImageStates: {
        uploadImageData,
        uploadImageStatus,
        uploadImageError,
      },
    },
    actions: { uploadImage },
  } = useContext<IUseMultipleUploadImage>(MultipleUploadImageContext);

  return (
    <Fragment>
      <Label label="Qo'shimcha rasm" />

      <MultipleUploadImageStyled>
        <UploadImage
          setUploadImageUrlList={setUploadImageUrlList}
          name={name}
          setValue={setValue}
        />
        {uploadImageUrlList?.map((imgUrl: string, index: number) => {
          return (
            <Fragment key={index}>
              <UploadImageCard
                imgUrl={imgUrl}
                index={index}
                setUploadImageUrlList={setUploadImageUrlList}
                setValue={setValue}
                name={name}
              />
            </Fragment>
          );
        })}

        {uploadImageStatus === REQUEST_STATUS.loading && (
          <UploadImageCard
            index={uploadImageUrlList.length}
            setUploadImageUrlList={setUploadImageUrlList}
            setValue={setValue}
            name={name}
          />
        )}
      </MultipleUploadImageStyled>
    </Fragment>
  );
};

export default MultipleUploadImage;
