import { lazy } from "react";
import { IRoute } from "../Routes.types";
const GenreRoutes = lazy(() => import("pages/genres"));
const BookRoutes = lazy(() => import("pages/books"));
const CategoryRoutes = lazy(() => import("pages/category"));
const PackageRoutes = lazy(() => import("pages/packages"));
const PublishingRoutes = lazy(() => import("pages/publishing"));
const OrderRoute = lazy(() => import("pages/orders"));
const CallRequestRoute = lazy(() => import("pages/callRequest"));
const UserRoutes = lazy(() => import("pages/users"));
const AuthorRoutes = lazy(() => import("pages/authors"));
const EmployeeRoutes = lazy(() => import("pages/hr/employees"));
const NewsRoutes = lazy(() => import("pages/news"));
const DiscountRoutes = lazy(() => import("pages/discount"));
const RoleRoutes = lazy(() => import("pages/hr/role"));
const SettingRoutes = lazy(() => import("pages/settings"));
const QuoteRoutes = lazy(() => import("pages/quote"));
const Login = lazy(() => import("pages/login/container"));
const NotFound = lazy(() => import("pages/notFound/container"));

// News Pages
const Banner = lazy(() => import("pages/banner"));
const PromoCode = lazy(() => import("pages/promoCode"));
const Partner = lazy(() => import("pages/partner"));
const Comment = lazy(() => import("pages/comment"));
const Dashboard = lazy(() => import("pages/dashboard"));

export const routes: IRoute[] = [
  {
    key: "book",
    isPrivate: true,
    path: "/",
    element: <Dashboard />,
  },
  {
    key: "book",
    isPrivate: true,
    path: "banner/*",
    element: <Banner />,
  },
  {
    key: "book",
    isPrivate: true,
    path: "promoCode/*",
    element: <PromoCode />,
  },
  {
    key: "book",
    isPrivate: true,
    path: "partner/*",
    element: <Partner />,
  },
  {
    key: "book",
    isPrivate: true,
    path: "comment/*",
    element: <Comment />,
  },
  {
    key: "genre",
    isPrivate: true,
    path: "genres/*",
    element: <GenreRoutes />,
  },
  {
    key: "book",
    isPrivate: true,
    path: "books/*",
    element: <BookRoutes />,
  },
  {
    key: "category",
    isPrivate: true,
    path: "category/*",
    element: <CategoryRoutes />,
  },
  {
    key: "package",
    isPrivate: true,
    path: "packages/*",
    element: <PackageRoutes />,
  },
  {
    key: "publisher",
    isPrivate: true,
    path: "publishing/*",
    element: <PublishingRoutes />,
  },
  {
    key: "order",
    isPrivate: true,
    path: "orders/*",
    element: <OrderRoute />,
  },
  {
    key: "order",
    isPrivate: true,
    path: "call-request/*",
    element: <CallRequestRoute />,
  },
  {
    key: "user",
    isPrivate: true,
    path: "users/*",
    element: <UserRoutes />,
  },
  {
    key: "author",
    isPrivate: true,
    path: "authors/*",
    element: <AuthorRoutes />,
  },
  {
    key: "role",
    isPrivate: true,
    path: "hr/roles/*",
    element: <RoleRoutes />,
  },
  {
    key: "employee",
    isPrivate: true,
    path: "hr/employees/*",
    element: <EmployeeRoutes />,
  },
  {
    key: "news",
    isPrivate: true,
    path: "news/*",
    element: <NewsRoutes />,
  },
  {
    key: "discount",
    isPrivate: true,
    path: "discount/*",
    element: <DiscountRoutes />,
  },
  {
    key: "delivery",
    isPrivate: true,
    path: "setting/*",
    element: <SettingRoutes />,
  },
  {
    key: "delivery",
    isPrivate: true,
    path: "quote/*",
    element: <QuoteRoutes />,
  },
  {
    key: "login",
    isPrivate: false,
    path: "login/*",
    element: <Login />,
  },
  {
    key: "pageNotFound",
    isPrivate: false,
    path: "page-not-found",
    element: <NotFound />,
  },
  {
    key: "notFound",
    isPrivate: false,
    path: "*",
    element: <NotFound />,
  },
];
