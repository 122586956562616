import styled from "styled-components";
import { theme } from "./../../../styles/theme/theme";

export const StyledInput = styled.input`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.grey};
  box-sizing: border-box;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.lightBlack};
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  outline: none;
  transition: all 300ms ease-out;
  min-width: 350px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.color.lightGrey};
  }
  &.error {
    border-color: ${({ theme }) => theme.colors.text.red};
  }
  @media screen and (max-width: 1200px) {
    min-width: 300px;
  }
  @media screen and (max-width: 1100px) {
    min-width: 250px;
  }
  @media screen and (max-width: 1000px) {
    min-width: 200px;
  }
`;
