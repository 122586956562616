import { useRequest } from "hooks";
import { IUseMultipleUploadImage } from "./MultipleUploadImageContext.types";

export const useMultipleUploadImage = (): IUseMultipleUploadImage => {
  const [
    uploadImageClient,
    uploadImageData,
    uploadImageStatus,
    uploadImageError,
  ] = useRequest();

  const uploadImage = (formData: FormData) => {
    uploadImageClient.post(process.env.REACT_APP_BASE_URL + "upload", formData);
  };

  return {
    state: {
      uploadImageStates: {
        uploadImageData,
        uploadImageStatus,
        uploadImageError,
      },
    },
    actions: { uploadImage },
  };
};
