import React from "react";

const DragenIcon = (props: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 2.5L7 0.5L9 2.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11.5L7 13.5L9 11.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 0.5V13.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 5L13.5 7L11.5 9"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5L0.5 7L2.5 9"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7H0.5"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DragenIcon;
