import React, { ReactNode } from "react";
import { MultipleUploadImageContext } from ".";
import { useMultipleUploadImage } from "./useMultipleUploadImage";

const MultipleUploadImageProvider = ({ children }: { children: ReactNode }) => {
  const value = useMultipleUploadImage();
  return (
    <MultipleUploadImageContext.Provider value={value}>
      {children}
    </MultipleUploadImageContext.Provider>
  );
};

export default MultipleUploadImageProvider;
