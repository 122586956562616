import { Checkbox, TableRow } from "@mui/material";
import React, { FC } from "react";
import { TableCellCheckbox, TableCellStyled } from "../../Table.style";
import { ITableColumn } from "../../Table.types";
import { TableHeadStyled } from "./TableHead.style";
import { ITableHead } from "./TableHead.types";

const TableHead: FC<ITableHead> = ({
  columns = [],
  selection,
  rowsPerPage,
}) => {
  return (
    <TableHeadStyled>
      <TableRow>
        {!selection && (
          <TableCellStyled>
            {rowsPerPage === -1 ? "Draggable" : "№"}
          </TableCellStyled>
        )}
        {selection && (
          <TableCellCheckbox
            onClick={(e) => e.stopPropagation()}
          ></TableCellCheckbox>
        )}

        {columns?.map((column: ITableColumn, index: number) => (
          <TableCellStyled key={index}>{column.title}</TableCellStyled>
        ))}
      </TableRow>
    </TableHeadStyled>
  );
};

export default TableHead;
