import { Select } from "@mui/material";
import styled from "styled-components";

export const LanguageSelectStyled = styled(Select)`
  padding: 0;
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 10px;
    padding-right: 35px;
    padding-left: 20px;
  }
  .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.border.lightBlack} !important;
    border-radius: 4px;
  }
`;
