import { Menu, ProSidebar } from "react-pro-sidebar";
import styled from "styled-components";
import { height } from "../Navbar/Navber.constants";
import { ISidebarHeaderStyled } from "./Sidebar.types";

export const ProSidebarStyled = styled(ProSidebar)`
  height: 100vh;
  min-width: 100%;
  width: 100%;
  transition: all 0.3s linear !important;
  overflow-y: hidden;
  .pro-sidebar-inner {
    transition: all 0.3s linear !important;
    background: #fff !important;
    min-width: 100%;
    width: 100%;
    .pro-sidebar-layout {
      overflow: hidden !important;
      .pro-sidebar-content {
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 3px !important;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.colors.bg.blue};
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;

export const MenuStyled = styled(Menu)<any>`
  padding: 0 !important;
  ul {
    .pro-menu-item {
      transition: all 0.1s linear;
      .pro-inner-item {
        &:hover {
          background-color: ${({ theme }) => theme.colors.bg.mainLightBg};
        }
        .pro-icon-wrapper {
          background-color: transparent !important;
        }
        .pro-item-content {
          color: ${({ theme }) => theme.colors.color.lightBlack} !important;
        }
      }
    }
    .pro-menu-item.active {
      border-left: 4px solid ${({ theme }) => theme.colors.color.blue};
    }
    .pro-menu-item.pro-sub-menu.active-parent {
      .pro-inner-item {
        background-color: ${({ theme }) => theme.colors.bg.mainLightBg};
        border-left: 4px solid ${({ theme }) => theme.colors.color.blue};
      }
    }
    .pro-menu-item.pro-sub-menu {
      .pro-inner-list-item {
        background-color: transparent !important;
        .popper-inner {
          background-color: #fff !important;
        }
        .pro-menu-item {
          border-left: none;
          .pro-inner-item {
            background: transparent;
            border-left: none;
          }
        }
      }
    }
  }
`;

export const SidebarHeaderStyled = styled.div<ISidebarHeaderStyled>`
  background-color: ${({ theme }) => theme.colors.bg.blue};
  position: relative;
  z-index: 100000;
  padding: 15px 0;
  min-height: ${height};
  height: ${height};
  .logo-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .logo-icon {
      display: ${(props) => (props.collapse ? "none" : "block")};
      width: 120px;
      height: 45px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .menu-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    right: ${(props) => (props.collapse ? "50%" : "10px")};
    transform: ${(props) =>
      props.collapse ? `translateX(50%)` : `translateX(auto)`} !important;
  }
`;
