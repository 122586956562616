import api from "services/api/client";

interface iQueryFn {
  url: string;
  method?: string;
  urlSearchParams?: Record<string, unknown>;
  ttl?: number;
  data?: unknown;
}

async function queryFn({
  url,
  method = "get",
  urlSearchParams = {},
  ttl = 0,
  data,
}: iQueryFn) {
  const options: any = {
    url,
    method,
    params: urlSearchParams,
    cache: {
      ttl, // 60 minute.
    },
    data,
  };
  const response = await api(options);

  return response;
}

export default queryFn;
