import GenericFormData, { AxiosInstance } from "axios";
import { QueryKey } from "@tanstack/react-query";

import api from "services/api/client";
import { IMethod, TKey, TParams } from "modules/_module";

export type TVariables = Record<string, unknown> & {
  id?: string | number;
  data: Record<string, unknown> | any;
};

interface ICommon {
  url: string;
  urlSearchParams?: Record<string, unknown> | Partial<TParams>;
  baseURL?: string;
  data: Record<string, unknown> | any;
}

interface IMutationFn {
  url: string | ((args: unknown) => string);
  route?: string;
  method?: string | IMethod;
  urlSearchParams?: Record<string, unknown>;
  variables: TVariables;
  onSuccess?: (data: unknown) => void;
  onError?: (err: unknown) => void;
  queryKey?: QueryKey;
  metaKey?: TKey;
  dataKey?: TKey;
  ttl?: number;
}

interface IPutRequest extends ICommon {
  url: string;
  id?: string | number;
  queryKey: QueryKey;
}

interface IDeleteRequest extends ICommon {
  id?: string | number;
  queryKey: QueryKey;
}

const putRequest = async (args: IPutRequest, request: AxiosInstance) => {
  const { url, urlSearchParams, queryKey, data, baseURL } = args;
  const res = await request({
    baseURL,
    url,
    method: "PUT",
    params: urlSearchParams,
    data: await data,
  });

  return res;
};

const postRequest = async (args: ICommon, request: AxiosInstance) => {
  const { url, urlSearchParams, data, baseURL } =  args;

  const options:any = {
    baseURL,
    url,
    method: "POST",
    params: urlSearchParams,
    data: data,
  };

  const res =  api(options);

  return res;
};

const patchRequest = async (args: ICommon, request: AxiosInstance) => {
  const { url, urlSearchParams, data, baseURL } = args;

  const options: any = {
    baseURL,
    url,
    method: "PATCH",
    params: urlSearchParams,
    data,
  };

  const res = await request(options);

  return res;
};

const deleteRequest = async (args: IDeleteRequest, request: AxiosInstance) => {
  const { url, urlSearchParams, id, queryKey, baseURL, data } = args;

  const options: any = {
    baseURL,
    url: id ? `${url}/${id}` : `${url}`,
    method: "delete",
    params: urlSearchParams,
    data,
  };
  const res = await request(options);

  return res;
};

const mutationFn = async (args: IMutationFn) => {
  const {
    url,
    queryKey = [],
    method = "post",
    urlSearchParams = {},
    variables,
  } = args;

  const reqUrl = typeof url === "function" ? url(variables) : url;
  const request = api;

  const { data = {}, id, route = "admin", extraUrl = "" } = variables;
  const baseURL = `${process.env.REACT_APP_BASE_URL}${route}`;

  let res;

  if (method === "put")
    res = await putRequest(
      {
        url: reqUrl + extraUrl,
        baseURL,
        urlSearchParams,
        id,
        queryKey,
        data,
      },
      request
    );
  if (method === "post")
    res = await postRequest(
      {
        url: reqUrl + extraUrl,
        baseURL,
        urlSearchParams,
        data,
      },
      request
    );
  if (method === "patch")
    res = await patchRequest(
      {
        url: reqUrl + extraUrl,
        baseURL,
        urlSearchParams,
        data,
      },
      request
    );
  if (method === "delete")
    res = await deleteRequest(
      { url: reqUrl + extraUrl, baseURL, urlSearchParams, id, queryKey, data },
      request
    );

  return res;
};

export default mutationFn;
