import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Container, Toolbar } from "@mui/material";
import AvatarComponent from "../Avatar/AvatarComponent";
import { StyledNavbar } from "./Navbar.style";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import browserStorage from "services/storage/browserStorage";
import get from "lodash.get";

const ResponsiveAppBar = ({ collapse }: any) => {
  const { i18n } = useTranslation();
  const userInfoGetStorage: any = browserStorage.get("admin");
  const userInfo = useMemo(() => {
    if (!!userInfoGetStorage) return JSON.parse(userInfoGetStorage);
    else return {};
  }, [userInfoGetStorage]);
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <StyledNavbar collapse={collapse}>
      <AppBar>
        <div className="px-4">
          <Toolbar disableGutters>
            <div className="navbarContent">
              <div className="languageSelect">
                <LanguageSelect />
              </div>
              <div className="navbarProfile">
                <div className="navbarProfileLeft">
                  <div className="name">
                    {get(userInfo, "firstName") +
                      " " +
                      get(userInfo, "lastName")}
                  </div>
                  <div className="text">{get(userInfo, "phoneNumber")}</div>
                </div>
                <div className="navbarProfileRight">
                  <AvatarComponent />
                </div>
              </div>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </StyledNavbar>
  );
};
export default ResponsiveAppBar;
