import React, { FC } from "react";
import { Controller } from "react-hook-form";
import Error from "../Error/Error";
import Label from "../Label/Label";
//@ts-ignore
import { MaskInputStyled } from "./PhoneInput.style";
//@ts-ignore
import { IPhoneInputProps } from "./PhoneInput.types";

const PhoneInput: FC<IPhoneInputProps> = ({
  defaultValue = "",
  error,
  placeholder = "",
  name = "phoneNumber",
  label = "",
  className = "",
  rules = { required: false },
  control,
  mask = "+998 99 999 99 99",
  maskChar = "",
}) => {
  return (
    <div className={className}>
      {!!label && <Label label={label} />}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { value, ...field } }) => {
          return (
            <MaskInputStyled
              mask={mask}
              //@ts-ignore
              alwaysShowMask={false}
              maskChar={maskChar}
              //@ts-ignore
              className={!!error && "error"}
              type="text"
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              {...field}
            />
          );
        }}
      />

      {!!error && <Error message={error.message} />}
    </div>
  );
};

export default PhoneInput;
