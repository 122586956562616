import { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { useTranslation } from "react-i18next";
import { SIDEBAR_LIST } from "./Sidebar.constants";
import {
  MenuStyled,
  ProSidebarStyled,
  SidebarHeaderStyled,
} from "./Sidebar.style";

import CollapseIcon from "./assets/icon/CollapseIcon";
import { ISidebarItem, SidebarPropsType } from "./Sidebar.types";
import Logo from "./assets/icon/Logo";
import RoleManager from "services/roleManagement";

import { isActive } from "./utils/isActive";

const Sidebar: FC<SidebarPropsType> = ({ collapse, setCollapse }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [hasAccess] = RoleManager();

  const handleNavigate = (path: any) => {
    if (path !== pathname) {
      navigate(path || "");
    }
  };

  const parentMenuActive = (children: ISidebarItem[]) => {
    return children?.find((child) => isActive(child.insidePaths, pathname));
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Escape") setCollapse((prev) => !prev);
    },
    [setCollapse]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <ProSidebarStyled>
      <ProSidebar collapsed={collapse} breakPoint="md">
        <SidebarHeaderStyled collapse={collapse}>
          <div className="logo-content">
            <div className="logo-icon">
              <Logo />
            </div>
          </div>
          <div
            className="menu-icon hover"
            onClick={() => setCollapse(!collapse)}
          >
            <CollapseIcon />
          </div>
        </SidebarHeaderStyled>
        <SidebarContent>
          <MenuStyled iconShape="circle" collapsed={collapse}>
            {SIDEBAR_LIST.map((item) => {
              if (item.children) {
                return (
                  <SubMenu
                    key={item.id}
                    icon={item.icon}
                    placeholder={undefined}
                    title={t(`SIDEBAR.${item.title}`)}
                    className={`${
                      parentMenuActive(item?.children) && "active-parent"
                    } `}
                    onPointerEnterCapture={() => console.log("Entered")}
                    onPointerLeaveCapture={() => console.log("Left")}
                  >
                    {item?.children?.map((child) => {
                      if (hasAccess(child.id)) {
                        return (
                          <MenuItem
                            key={child.id + "child"}
                            onClick={() => handleNavigate(child.path)}
                            className={`  ${
                              isActive(child.insidePaths, pathname) && "active"
                            } `}
                          >
                            {t(`SIDEBAR.${child.title}`)}
                          </MenuItem>
                        );
                      } else return null;
                    })}
                  </SubMenu>
                );
              }
              if (!!item) {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => handleNavigate(item.path)}
                    icon={item.icon}
                    className={` ${
                      isActive(item.insidePaths, pathname) ? "active" : ""
                    }`}
                  >
                    {t(`SIDEBAR.${item.title}`)}
                  </MenuItem>
                );
              } else return null;
            })}
          </MenuStyled>
        </SidebarContent>
      </ProSidebar>
    </ProSidebarStyled>
  );
};

export default Sidebar;
